
import { 
    IonPage, 
    IonHeader, 
    IonTitle,
    IonToolbar, 
    IonButtons,
    IonMenuButton,
    IonContent, 
    IonRow, 
    IonCol,
    IonIcon, 
    IonSpinner,
    IonFooter,
    IonToggle,
    modalController,
    IonList,
    IonItem,
    IonPopover
} from '@ionic/vue';
import { 
          busOutline, 
          cogOutline,
          locateOutline, 
          arrowBackCircleOutline,
          radioButtonOnOutline
        } from 'ionicons/icons';
import { Geolocation } from '@capacitor/geolocation';
import { defineComponent } from "vue";
import { RideService } from './../../services/ride.service';
import TrackSettingModal from './../../components/TrackSettingModal.vue';

export default  defineComponent({
  name: 'TrackPage',
  components: { 
    IonPage,
    IonHeader,
    IonTitle, 
    IonToolbar, 
    IonButtons,
    IonMenuButton,
    IonContent, 
    IonRow,  
    IonCol,
    IonIcon, 
    IonSpinner,
    IonFooter,
    IonToggle,
    IonList,
    IonItem,
    IonPopover,
  },
  data() {
      return {
          showSpinner: false,
          busNumber: '',
          latitude: 0.00,
          longitude: 0.00,
          coords: null,
          showTracking: true,
          trackingText: '',
          wait: '',
          showTrackingStatus: false,
          trackingStatusIcon: 'default-status-icon',
          trackingStatusMessage: '',
          iconState: false,
      }
  },
  setup() {
      return {
        busOutline,
        cogOutline,
        locateOutline,
        arrowBackCircleOutline,
        radioButtonOnOutline,
      }
  },
  methods: {
    ionViewWillEnter() {
        const busNumber = this.$route.params.busNumber;
        this.busNumber = busNumber.toString();
        this.startTracking();
        this.loadCurrentLocation();
    },

    async adjustSetting() {
      const showModal = await modalController.create({
        id: 'trackSettingComponent',
        component: TrackSettingModal,
        backdropDismiss: false,
        cssClass: 'track-setting-modal',
    });
    return showModal.present();
    },

    async startTracking() {
      this.showSpinner = true;
      const queryParams = `busNumber=${this.busNumber}`;
      RideService.startTracking(queryParams).then((res) => { 
          this.showSpinner = false;
          this.networkStatus(true);
          //this.loadCurrentLocation();
          console.log('Res', res);
      }).catch((errors: any) => {
        this.networkStatus(false);
        this.showSpinner = false;
        //console.log(errors);
        
      });
    },

    async loadCurrentLocation() {
      // Geolocate user current location
    if (!this.coords) {
      const coordinates = await Geolocation.getCurrentPosition();
      const coords = coordinates.coords;
      this.latitude = coords.latitude;
      this.longitude = coords.longitude;
      //this.loadMap(this.latitude, this.longitude);
      this.trackingText = 'Tracking OFF.';
    }
    
    },

    async stopTrack(event: any) {
      if (event.target.ariaChecked === 'true') {
        this.stopTracking();
        this.trackingText = 'Tracking OFF.';
      } else if (event.target.ariaChecked === 'false'){
        this.track();
        this.trackingText = 'Tracking ON.';
      }
    },

    async stopTracking() {
      Geolocation.clearWatch({ id: this.wait });
    },

    async track() {
      console.log('Am here,');
    },

    async networkStatus(status: boolean) {
      //if(status) {
        setInterval(() => {
          this.iconState = !this.iconState;
          if(status) {
            this.trackingStatusMessage = 'Good';
            this.showTrackingStatus = true;
            this.trackingStatusIcon = (this.iconState ? 'good-status-icon' : 'default-status-icon');
          } else {
            this.trackingStatusMessage = 'Bad';
            this.showTrackingStatus = true;
            this.trackingStatusIcon = (this.iconState ? 'bad-status-icon' : 'default-status-icon');
          }
        }, 1000);
      //}
    }
  }
})
