
import { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonButtons,
    IonMenuButton,
    IonContent, 
    IonRow, 
    IonCol, 
    IonIcon, 
    IonFooter 
  } from '@ionic/vue';
import { 
          busOutline, 
          locateOutline, 
          arrowBackCircleOutline 
        } from 'ionicons/icons';

export default  {
  name: 'RiderPage',
  components: { 
          IonHeader, 
          IonToolbar, 
          IonButtons,
          IonMenuButton,
          IonContent, 
          IonPage, 
          IonRow, 
          IonCol, 
          IonIcon, 
          IonFooter
          
  },
  data() {
    return {

    }
  },
  setup() {
    return {
      busOutline,
      locateOutline,
      arrowBackCircleOutline,
    }
  },

  methods: {
    async searchRides(event: any) {
      console.log('Event', event)
    }
  }
}
