import { RideService, ResponseError } from "@/services/ride.service";

const state = {
    responseData: "",
    responseErrorCode: 0,
    responseError: "",
};

const getters = {
    responseErrorCode: (state: { responseErrorCode: any }) => {
        return state.responseErrorCode;
    },
    responseError: (state: { responseError: any }) => {
        return state.responseError;
    }
};

const actions = {
    async loadSecretArea(context: any) {
        context.commit("dataRequest");
        try {
            //const resp = await RideService.secretArea();
            const resp = 'gggggggggggggg';
            context.commit("dataSuccess", resp);
            return resp;
        } catch (errors: any) {
            if (errors instanceof ResponseError) {
                context.commit("dataError", {
                    errorMessage: errors.errorMessage,
                    responseErrorCode: errors.errorCode
                });
            }
            return errors.message;
        }
    }
};

const mutations = {
    dataRequest(state: {
        responseError: string;
        responseErrorCode: number;
    }) {
        state.responseError = "";
        state.responseErrorCode = 0;
    },
    dataSuccess(state: { responseData: string }, payload: any) {
        state.responseData = payload;
    },
    dataError(state: {
        responseError: any;
        responseErrorCode: any;
        }, {errorCode, errorMessage}: any) {
        state.responseError = errorMessage;
        state.responseErrorCode = errorCode;
    }
}

export const home = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};