import ApiService from "./api.service";

class ResponseError extends Error {
    errorCode: any;
    errorMessage: any;
    constructor(errorCode: any, message: string | undefined) {
        super(message);
        this.name = this.constructor.name;
        if (message != null) {
            this.message = message;
        }
        this.errorCode = errorCode;
    }
}

const RideService = {
    getBuses: async (queryParams: string) => {
        try {
            return ApiService.get(`/bus/list?${queryParams}`);
        } catch(error: any) {
            throw new ResponseError(
                error.status,
                error.error.message,
            )
        }
    },

    startTracking: async(queryParams: string) => {
        try {
            return ApiService.get(`/bus/track?${queryParams}`);
        } catch(error: any) {
            throw new ResponseError(
                error.status,
                error.error.message,
            )
        }
    },

    updateTrackSetting: async(formData: any) => {
        try {
        return ApiService.post(`/bus/setting`, formData);
        } catch(error: any) {
            throw new ResponseError(
                error.status,
                error.error.message,
            )
        }
    },

    getRadiusOptions() {
        return [
            {id: 1, label:'0.1 miles', value: 0.1}, 
            {id: 2, label: '0.2 miles', value: 0.2},
            {id: 3, label: '0.25 miles', value: 0.25}, 
            {id: 4, label: '0.3 miles', value: 0.3},
            {id: 5, label: '0.35 miles', value: 0.35},
            {id: 6, label: '0.4 miles', value: 0.4}, 
            {id: 7, label: '0.45 miles',value: 0.45}, 
            {id: 8, label: '0.5 miles', value: 0.5}, 
            {id: 9, label: '0.6 miles', value: 0.6},
            {id: 10, label: '0.7 miles', value: 0.7},
            {id: 11, label: '0.8 miles', value: 0.8},
            {id: 12, label: '0.9 miles', value: 0.9},
            {id: 13, label: '1 miles', value: 1},
            {id: 14, label: '10 miles', value: 10},
            {id: 15, label: '10 miles', value: 10},
            {id: 16, label: '20 miles', value: 20},
            {id: 17, label: '50 miles', value: 50},
            {id: 18, label: '100 miles', value: 100},
          ]
    }
}

export { RideService, ResponseError };