
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LogoutPage',
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  created() {
      console.log('Logout');
      this.router.push("/home")
  }
});
