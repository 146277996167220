
import { 
    IonPage, 
    IonHeader, 
    IonTitle,
    IonSearchbar,
    IonToolbar, 
    IonButtons,
    IonMenuButton,
    IonContent, 
    IonRow, 
    IonCol, 
    IonIcon,
    IonAvatar, 
    IonSpinner,
    IonFooter,
    alertController
  } from '@ionic/vue';
import { 
          busOutline, 
          locateOutline, 
          arrowBackCircleOutline 
        } from 'ionicons/icons';
import { defineComponent } from "vue";
import { RideService } from './../../services/ride.service';
import { useRouter } from 'vue-router';

export default  defineComponent({
  name: 'RidePage',
  components: { 
          IonPage,
          IonHeader,
          IonTitle, 
          IonSearchbar,
          IonToolbar, 
          IonButtons,
          IonMenuButton,
          IonContent, 
          IonRow, 
          IonCol, 
          IonIcon, 
          IonAvatar,
          IonSpinner,
          IonFooter,
          
  },
  data() {
    return {
      showSpinner: false,
      buses: [],
    }
  },
  setup() {
    const router = useRouter();
    return {
      router,
      busOutline,
      locateOutline,
      arrowBackCircleOutline,
    }
  },
  methods: {
    ionViewWillEnter() {
      this.getBuses('');
    },

    async searchRides(event: any) {
      const keyword = event.target.value;
      this.getBuses(keyword);
    },

    async getBuses(searchKey: string) {
      this.showSpinner = true;
      const cityNumber = 1975938;
      const queryParams = `cityNumber=${cityNumber}&searchKey=${searchKey}`;
      RideService.getBuses(queryParams).then((res) => { 
          this.showSpinner = false;
          this.buses = res.data.ResultSet.list; 
      }).catch((errors: any) => {
        this.showSpinner = false;
        console.log(errors);
        
      });
    },
    
    async startTracking(busNumber: string) {
      alertController.create({
        header: 'Start tracking?',
        message: `Start tracking bus number: ${busNumber}`,
        buttons: [
          {
            text: 'Yes',
            handler: () => {
              this.router.push(`/ride/track/${busNumber}`);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      }).then(alertEl => {
        alertEl.present();
      });
    }
    
  }
})
