
import { 
        IonPage, 
        IonHeader, 
        IonToolbar, 
        IonTitle, 
        IonContent, 
        IonIcon, 
        IonFooter,
        IonCard,
        IonCardContent,
        IonGrid,
        IonRow,
        IonCol,
        IonItem,
        IonLabel,
        } from '@ionic/vue';
import { defineComponent } from 'vue';
import { locateOutline,  busOutline, personOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'HomePage',
  components: {
              IonPage,
              IonHeader,
              IonTitle,
              IonToolbar,
              IonContent,
              IonIcon,
              IonFooter,
              IonCard,
              IonCardContent,
              IonGrid,
              IonRow,
              IonCol,
              IonItem,
              IonLabel,

  },
  setup() {
    return {
            locateOutline, 
            busOutline, 
            personOutline
    }
  }
});
