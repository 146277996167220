import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import RidePage from '../views/ride/RidePage.vue';
import TrackPage from '../views/ride/TrackPage.vue';
import RiderPage from '../views/rider/RiderPage.vue';
import LogoutPage from '../views/LogoutPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },{
    path: '/home',
    name: 'Home',
    component: HomePage
  },{
    path: '/ride',
    name: 'RidePage',
    component: RidePage,
  },{
    path: '/ride/track/:busNumber',
    component: TrackPage,
  },{
    path: '/rider',
    name: 'RiderPage',
    component: RiderPage
  },{
    path: '/logout',
    name: 'LogoutPage',
    component: LogoutPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
