
import { 
  IonContent, 
  IonHeader, 
  IonTitle, 
  IonToolbar,
  IonSelectOption,
  IonSelect,
  modalController,
  IonIcon,
  IonButton,
  IonGrid,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  } from "@ionic/vue";
  import { 
          closeCircle, 
          cogOutline
        } from 'ionicons/icons';
import { defineComponent } from "vue";
import { RideService } from './../services/ride.service';

export default defineComponent({
  name: "TrackSettingModal",
  components: { 
    IonContent, 
    IonHeader, 
    IonTitle, 
    IonToolbar,
    IonSelectOption,
    IonSelect,
    IonIcon,
    IonButton,
    IonGrid,
    IonCard,
    IonCardContent,
    IonItem,
    IonLabel,
    },
    data() {
      return {
        form: {
          radius: 0.4,
        },
        radiusOptions: RideService.getRadiusOptions(),
      }
    },
    setup() {
      return {
        closeCircle,
        cogOutline,
      }
    },
    methods: {
      async handleTrackSetting() {
      console.log('formData', this.form);
      try {
        const updated = await RideService.updateTrackSetting(this.form);
      } catch(errors: any) {
        console.log('am here');
        throw new Error(`Errors: ${errors.message}`);
      }
      
      },

      async dismissModal() {
        await modalController.dismiss();
      }
    }
});
