
import {  IonApp, 
          IonMenu, 
          IonHeader, 
          IonToolbar, 
          IonTitle, 
          IonContent, 
          IonList, 
          IonItem,
          IonIcon,
          IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { personOutline,  lockClosedOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonRouterOutlet
  },
  setup() {
    return { 
            personOutline,
            lockClosedOutline,
    }
  },
});

